define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-personas-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseAiPersonasShow extends _discourse.default {
    async model(params) {
      const allPersonas = this.modelFor("adminPlugins.show.discourse-ai-personas");
      const id = parseInt(params.id, 10);
      return allPersonas.findBy("id", id);
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("allPersonas", this.modelFor("adminPlugins.show.discourse-ai-personas"));
    }
  }
  _exports.default = AdminPluginsShowDiscourseAiPersonasShow;
});